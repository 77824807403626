@font-face {
font-family: '__recifeTextFont_092786';
src: url(/_next/static/media/725a4d866aa5c807-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__recifeTextFont_Fallback_092786';src: local("Arial");ascent-override: 88.38%;descent-override: 22.44%;line-gap-override: 0.00%;size-adjust: 108.29%
}.__className_092786 {font-family: '__recifeTextFont_092786', '__recifeTextFont_Fallback_092786'
}

@font-face {
font-family: '__gtAmericaFont_9f3be5';
src: url(/_next/static/media/3f585ac2e86e721f-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__gtAmericaFont_Fallback_9f3be5';src: local("Arial");ascent-override: 101.04%;descent-override: 26.07%;line-gap-override: 0.00%;size-adjust: 98.97%
}.__className_9f3be5 {font-family: '__gtAmericaFont_9f3be5', '__gtAmericaFont_Fallback_9f3be5'
}

